<!--  通讯录-->
<template>
  <div class="personal-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#0094EE',
        title: '通讯录',
        txtColor: '#fff',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <div v-if="userInfo">
        <div class="top-info">
          <div class="react-name">
            {{ userInfo.userName | sclieName }}
          </div>
          <div class="right-box">
            <p class="staff-name" :title="userInfo.userName">
              {{ userInfo.userName }}
            </p>
            <p class="staff-duty">{{ namePath }}/{{ userInfo.staffDuty }}</p>
          </div>
        </div>
        <div class="bottom-info">
          <div class="list-item">
            <p class="label-name">工号</p>
            <p class="user-value">{{ userInfo.staffCode }}</p>
          </div>
          <div class="list-item">
            <p class="label-name">手机号</p>
            <p class="user-value">{{ userInfo.phoneNumber }}</p>
          </div>
          <div class="list-item">
            <p class="label-name">邮箱号</p>
            <p class="user-value">{{ userInfo.emailAddress }}</p>
          </div>
        </div>
        <div class="call-info">
          <a class="call-btn" id="call" @click="callPhone">
            <van-icon name="phone-o" size="30" />
          </a>
        </div>
        <van-popup
          v-model="show"
          title="拨打电话"
          :message="userInfo.phoneNumber"
          @cancel="cancel"
          @confirm="contact"
          position="bottom"
          :style="{ height: '21%' }"
          closeable
        >
          <div class="call-content">
            <p class="call-title">拨打电话</p>
            <p class="phone-num" @click="contact">{{ userInfo.phoneNumber }}</p>
            <!-- <div class="btn">
              
              <span class="sure-btn" @click="contact">确认</span>
            </div> -->
          </div>
        </van-popup>
      </div>
      <van-empty v-else description="暂无数据" />
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/addressList/index.js";
import { staffInfo } from "@/api/staff/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      userInfo: null,
      namePath: "",
      show: false,
    };
  },
  filters: {
    sclieName(name) {
      if (!name) return "--";

      let len = name.length;
      let s = 0;
      if (len > 2) {
        s = len - 2;
      }
      return name.substring(s, len);
    },
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    if (this.$route.query) {
      let { id, name } = this.$route.query;
      if (id) {
        this.getStaffInfo(id);
      }
    }
  },

  methods: {
    cancel() {
      this.show = false;
    },
    contact() {
      const a = document.getElementById("call");
      a.setAttribute("href", `tel:${this.userInfo.phoneNumber}`);
      a.click();
      this.show = false;
    },
    callPhone() {
      this.show = true;
    },
    /* 获取部门 */
    getStaffInfo(code) {
      staffInfo(code).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.userInfo = data;
            if (this.userInfo.departmentName) {
              if (this.userInfo.departmentName.indexOf(",") > 0) {
                this.namePath = this.userInfo.departmentName.replaceAll(
                  ",",
                  "/"
                );
              } else {
                this.namePath = this.userInfo.departmentName;
              }
            } else {
              this.namePath = name;
            }
            // this.namePath = name;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.personal-page {
  width: 100%;
  min-height: 100%;
  height: auto;
  background: #fafafa;
  position: relative;
  padding-bottom: 0.2rem;
  .main-wrapper {
    padding: 0rem 0rem;
    .top-info {
      padding: 0rem 0.3rem;
      width: 100%;
      min-height: 1.76rem;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      .react-name {
        width: 1.16rem;
        height: 1.16rem;
        background: #0094ee;
        border-radius: 0.16rem;
        text-align: center;
        line-height: 1.16rem;
        color: #fff;
        font-size: 0.3rem;
        flex: 0 0 1.16rem;
      }
      .right-box {
        flex: 1;
        margin-left: 0.24rem;
        .staff-name {
          font-size: 0.36rem;
          font-weight: 400;
          color: #333333;
          margin-bottom: 0.2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        .staff-duty {
          font-size: 0.26rem;
          font-weight: 400;
          color: #999999;
          letter-spacing: 0.02rem;
        }
      }
    }
    .bottom-info {
      margin-top: 0.3rem;
      padding: 0rem 0.3rem;
      width: 100%;
      min-height: 1.76rem;
      background: #ffffff;
      .list-item {
        padding: 0.3rem 0;
        border-bottom: 0.01rem solid #e5e5e5;
        .label-name {
          font-size: 0.26rem;
          font-weight: 400;
          color: #999999;
          margin-bottom: 0.09rem;
          line-height: 0.37rem;
        }
        .user-value {
          font-size: 0.3rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.42rem;
        }
      }
      .list-item:last-child {
        border: none;
      }
    }
    .call-info {
      position: absolute;
      bottom: 0.8rem;
      left: 50%;
      margin-left: -0.44rem;
      .call-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0.88rem;
        height: 0.88rem;
        background: #0094ee;
        color: #fff;

        border-radius: 50%;
      }
    }
    .call-content {
      width: 100%;
      .call-title {
        width: 100%;
        text-align: center;
        padding-top: 0.4rem;
        font-weight: 500;
        line-height: 0.3rem;
        font-size: 0.3rem;
      }
      .phone-num {
        color: #0094ee;
        padding: 0.7rem 0.24rem;
        overflow-y: auto;
        font-size: 0.24rem;
        line-height: 0.24rem;
        white-space: pre-wrap;
        text-align: center;
        word-wrap: break-word;
      }
      .btn {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 0.01rem solid #ebedf0;
        height: 0.8rem;
        .cancle-btn,
        .sure-btn {
          width: 100%;
          text-align: center;
          font-weight: 500;

          line-height: 0.8rem;
          padding: 0.1rem 0rem;
        }
        .sure-btn {
          color: #ee0a24;
        }
      }
    }
  }
}
</style>
